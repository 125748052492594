<!--
 * @Date: 2021-03-21 11:12:36
 * @LastEditors: frank
 * @LastEditTime: 2021-05-15 14:01:49
 * @FilePath: /shop_frontend/src/views/memberList/pointsDetail.vue
 * @Description: Description
-->
<template>
  <div class="page">
    <div class="page__btns">
      <a-form :form="form" class="search-form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="会员昵称">
              <a-input placeholder="会员昵称" v-model="form.member_name" class="w220" allowClear></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单号">
              <a-input placeholder="订单号" v-model="form.order_number" class="w220" allowClear></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="时间">
              <a-range-picker
                allowClear
                v-model="form.searchDate"
                valueFormat="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <a-button
                type="primary"
                @click="handleSearch"
                style="margin-right: 10px"
              >
                查询
              </a-button>
              <a-button type="primary" style="margin-right: 10px" @click="exportExcel">
                导出表格
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="pagination"
      bordered
      @change="changeTable"
    >
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "会员昵称",
    dataIndex: "member_name",
    align: 'center'
  },
  {
    title: "手机号码",
    dataIndex: "member_phone",
    align: 'center'
  },
  {
    title: "订单号",
    dataIndex: "order_number",
    align: 'center'
  },
    {
    title: "类型",
    dataIndex: "type_display",
    align: 'center'
  },
  {
    title: "变更前余额",
    dataIndex: "before_change",
    align: 'center'
  },
  {
    title: "变更余额",
    dataIndex: "amount",
    align: 'center'
  },
  {
    title: "变更后余额",
    dataIndex: "after_change",
    align: 'center'
  },
  {
    title: "时间",
    dataIndex: "create_time",
    align: 'center'
  },
  {
    title: "备注",
    dataIndex: "remark",
    align: 'center'
  },
];
import qs from 'qs'
export default {
  data() {
    return {
      dataSource: [],
      searchVal: "",
     
      form: {
        member_name: "",
        searchDate: [],
      },
      columns,
      page: 1,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
    };
  },
  created() {
    let info = JSON.parse(this.$route.query.info);
    console.log(info,'info')
    this.form.member_name = info.name
    this.initTable();
  },
  computed: {
    params() {
      return { page: this.page, ...this.form, start_date: this.form.searchDate[0], end_date: this.form.searchDate[1]};
    },
  },
  methods: {
            convertRes2Blob(response) {
      // 提取文件名
      // const fileName = response.headers['content-disposition'].match(
      //   /filename=(.*)/
      // )[1]
      const filename = "积分流水.xlsx";
      // 将二进制流转为blob
      const blob = new Blob([response.data], {
        // type: "application/octet-stream",
        // type: "application/octet-stream",
        // type: 'application/ms-excel'
      });
      if (typeof window.navigator.msSaveBlob !== "undefined") {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(blob, decodeURI(filename));
      } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob);
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", decodeURI(filename));
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        // 挂载a标签
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL);
      }
    },
    exportExcel() {
      let params = qs.stringify(this.params);
       let excelUrl =
        window.location.origin + "/api/integral_flows/export/?" + params;
        window.location.href = excelUrl;
      // this.$axios
      //   .get("/integral_flows/export/", { params: this.params ,responseType: 'blob', headers: { 'Content-Type': 'application/vnd.ms-excel' }})
      //   .then((res) => {
      //     console.log(res);
      //     this.convertRes2Blob(res);
      //   });
    },
    handleSearch() {
      this.initTable();
    },
    search() {
      this.page = 1;
      this.initTable();
    },
    changeTable(pagination) {
      console.log(pagination);
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios.get("/integral_flows/", { params: this.params }).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.search-form .ant-form-item {
  display: flex;
}

.search-form .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
